const blogHeader = document.querySelector(".blog-header");

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function(callback, thisArg) {
        thisArg = thisArg || window;
        for (let i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

function setTimestamp() {
    const day = new Date();
    const time = day.getTime();
    const timeDelay = day.setHours(day.getHours() + 1);
    const timestamp = parseInt(localStorage.getItem("partnerReadTimestamp"));

    if (!localStorage.getItem("readPosts")) {
        localStorage.setItem("readPosts", "");
    }

    if (!timestamp || timestamp < time) {
        localStorage.setItem("partnerReadTimestamp", timeDelay);
        getIds();
    } else {
        cacheBuster(timeDelay);
    }
}

function getIds() {
    jQuery.ajax({
        url: partnerNews.ajax_url,
        type: "post",
        data: {
            action: "unread_posts",
            nonce: partnerNews.ajax_nonce,
        },
        success(response) {
            const responseJson = JSON.parse(response);
            if (responseJson !== null) {
                markPosts(responseJson);
                localStorage.setItem("partnerNewsUnread", responseJson);
            }
        },
    });
}

function cacheBuster(timeDelay) {
    jQuery.ajax({
        url: partnerNews.ajax_url,
        type: "post",
        data: {
            action: "cache_destroyer",
            nonce: partnerNews.ajax_nonce,
        },
        success(response) {
            const responseJson = JSON.parse(response);
            if (responseJson === true) {
                getIds();
                localStorage.setItem("partnerReadTimestamp", timeDelay);
            }
        },
    });
}

function markPosts(postIds) {
    const allPosts = document.querySelectorAll(".pn-post");
    const readPosts = localStorage.getItem("readPosts");

    for (let i = 0; i < allPosts.length; i++) {
        const pnId = parseInt(allPosts[i].dataset.postId);
        if (postIds.indexOf(pnId) !== -1 && readPosts.indexOf(pnId) === -1) {
            allPosts[i].classList.add("show-new");
        }
    }
}

function keepMarked() {
    const unreadPosts = localStorage.getItem("partnerNewsUnread");

    if (unreadPosts) {
        markPosts(unreadPosts);
    }
}

function navBarHeight() {
    return document.querySelector(".navbar").offsetHeight;
}

function createGoBack() {
    const backDivLink = document.createElement("a");
    const backDiv = document.createElement("div");
    const backDivText = document.createElement("p");

    backDivLink.setAttribute("href", "/");
    backDivLink.setAttribute("title", "Go back to Miracle Messenger");
    backDivLink.classList.add("go-back-link");
    backDivLink.style.top = "-5px";

    backDiv.classList.add("go-back");

    backDivText.textContent = "Go Back To Miracle Messenger";

    blogHeader.appendChild(backDivLink);
    backDivLink.appendChild(backDiv);
    backDiv.appendChild(backDivText);
}

// For older browsers
function goBackLogic() {
    const scrollTop = window.pageYOffset;
    const headerHeight = blogHeader.offsetHeight;

    if (scrollTop < headerHeight) {
        showGoBack();
    } else {
        hideGoBack();
    }
}

function showGoBack() {
    const height = navBarHeight();
    const backDivWrapper = document.querySelector(".go-back-link");
    backDivWrapper.style.top = `${height}px`;
}

function hideGoBack() {
    const backDivWrapper = document.querySelector(".go-back-link");
    backDivWrapper.style.top = "-5px";
}

function createObserver(target) {
    const options = {
        root: null,
        rootMargin: "0%",
        threshold: [ 0.4 ],
    };
    const observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(target);
}

function handleIntersect(entries) {
    entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRatio > 0.4) {
            showGoBack();
        } else if (window.scrollY === 0) {
            showGoBack();
        } else {
            hideGoBack();
        }
    });
}

jQuery.fn.almComplete = function() {
    keepMarked();
};

setTimestamp();
keepMarked();

if (blogHeader) {
    createGoBack();

    if ("IntersectionObserver" in window) {
        // Create observer for blog header, to hide back button
        createObserver(document.querySelector(".blog-header"));

        // Create observer for comments section, to show back button
        createObserver(document.querySelector(".post-navigation"));
    } else {
        showGoBack();
        let ticking = false;
        window.addEventListener("scroll", () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    goBackLogic();
                    ticking = false;
                });

                ticking = true;
            }
        });
    }
}
